// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

.next-video-popup-container {
    display: flex;
    flex-direction: row;
    width: 38rem;
    min-height: 13rem;
    animation: slide-fade-in 0.5s ease-in;

    @keyframes slide-fade-in {
        0% {
            opacity: 0;
            transform: translateX(calc(40rem + 2rem));
        }

        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    .poster-container {
        flex: 1 1 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--overlay-color);

        .poster-image {
            flex: none;
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;

            &.blurred {
                filter: blur(0.5rem);
                -webkit-filter: blur(0.5rem);
            }
        }

        .placeholder-icon {
            flex: none;
            width: 80%;
            height: 50%;
            color: var(--primary-foreground-color);
        }
    }

    .info-container {
        flex: 1 1 55%;
        display: flex;
        flex-direction: column;

        .details-container {
            flex: auto;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 1.5rem 2rem;

            .name {
                flex: none;
                align-self: stretch;
                max-height: 2.4em;
                font-weight: 700;
                color: var(--primary-accent-color);

                .label {
                    color: var(--primary-foreground-color);
                }
            }

            .title {
                flex: none;
                align-self: stretch;
                max-height: 2.4em;
                font-weight: 500;
                color: var(--primary-foreground-color);
            }
        }

        .buttons-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 1rem;
            padding: 0 1rem 1.5rem;

            .spacing {
                flex: 0 0 50%;
            }

            .button-container {
                flex: 0 1 50%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                height: 3.5rem;
                padding: 0 1rem;
                border-radius: 1.75rem;

                &.play-button {
                    background-color: var(--primary-accent-color);

                    .icon {
                        color: var(--primary-foreground-color);
                    }

                    .label {
                        color: var(--primary-foreground-color);
                    }

                    &:hover {
                        outline: var(--focus-outline-size) solid var(--primary-accent-color);
                        background-color: transparent;
                    }
                }

                .icon {
                    flex: none;
                    width: 1.4rem;
                    height: 1.4rem;
                    color: var(--primary-foreground-color);
                }

                .label {
                    flex: none;
                    max-height: 2.4em;
                    font-size: 1.1rem;
                    font-weight: 500;
                    color: var(--primary-foreground-color);
                }

                &.dismiss {
                    opacity: 0.6;
                }

                &:hover, &:focus {
                    background-color: var(--overlay-color);
                }
            }
        }
    }
}

@media @phone-portrait {
    .next-video-popup-container {
        .info-container {
            .buttons-container {
                gap: 0.5rem;

                .button-container {
                    margin-left: 0rem;

                    .icon {
                        margin-right: 0rem;
                    }
    
                    .label {
                        display: none;
                    }
                }
            }
        }
    }
}