// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

:import('~stremio/components/Popup/styles.less') {
    context-menu-container: menu-container;
    menu-direction-top-left: menu-direction-top-left;
    menu-direction-bottom-left: menu-direction-bottom-left;
    menu-direction-top-right: menu-direction-top-right;
    menu-direction-bottom-right: menu-direction-bottom-right;
}

.video-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: var(--border-radius);

    &:hover,
    &:focus,
    &:global(.active) {
        background-color: var(--overlay-color);
    }

    .thumbnail-container {
        flex: none;
        position: relative;
        border-radius: 0.3rem;
        overflow: hidden;

        .thumbnail {
            pointer-events: none;
            display: block;
            height: 5rem;
            width: 8rem;
            object-fit: cover;
            object-position: center;
            opacity: 0.9;
            background-color: var(--overlay-color);

            &.blurred {
                filter: blur(0.5rem);
                -webkit-filter: blur(0.5rem);
            }
        }

        .placeholder-icon {
            display: block;
            height: 5rem;
            width: 8rem;
            padding: 1rem;
            color: var(--primary-foreground-color);
            background-color: var(--overlay-color);
            opacity: 0.25;
        }

        .progress-bar-container {
            position: absolute;
            bottom: 0.5rem;
            left: 0.5rem;
            right: 0.5rem;
            border-radius: var(--border-radius);

            .progress-bar {
                z-index: 1;
                position: relative;
                height: 0.4rem;
                background-color: var(--primary-accent-color);
            }

            .progress-bar-background {
                z-index: 0;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: var(--primary-foreground-color);
                opacity: 0.2;
            }
        }
    }

    .info-container {
        flex: 1;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 0.5rem 0 1.5rem;

        .title-container {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 1rem;
            color: var(--primary-foreground-color);
        }

        .flex-row-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            .released-container {
                flex: 1;
                margin-right: 0.5rem;
                padding: 0.2rem 0;
                font-size: 0.8rem;
                font-weight: 500;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: var(--primary-foreground-color);
                opacity: 0.44;
            }

            .upcoming-watched-container {
                flex: 0 1 auto;
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 1.6rem;
                border-radius: 0.3rem;

                &>:nth-child(2) {
                    margin-left: 0.5rem;
                }

                .upcoming-container,
                .watched-container {
                    flex: none;
                    display: flex;
                    align-items: center;
                    height: 100%;
                    padding: 0 0.5rem;
                    max-width: 10rem;

                    &:not(:only-child) {
                        max-width: 5rem;
                    }

                    .flag-icon {
                        height: 1.15rem;
                        width: 1.15rem;
                        margin-right: 0.25rem;
                        color: var(--secondary-foreground-color);
                    }

                    .flag-label {
                        font-size: 0.8rem;
                        font-weight: 800;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        text-transform: uppercase;
                        color: var(--secondary-foreground-color);
                    }
                }

                .upcoming-container {
                    background-color: var(--secondary-accent-color);
                }

                .watched-container {
                    background-color: var(--tertiary-accent-color);
                }
            }
        }
    }

    .context-menu-container {
        max-width: calc(90% - 1.5rem);
        z-index: 2;

        .context-menu-content {
            --spatial-navigation-contain: contain;

            .context-menu-option-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 1rem 1.5rem;

                &:hover,
                &:focus {
                    background-color: var(--overlay-color);
                }

                .context-menu-option-label {
                    font-size: 1rem;
                    font-weight: 500;
                    color:var(--primary-foreground-color);
                }
            }
        }
    }
}

@media only screen and (max-width: @minimum) {
    .video-container {
        .context-menu-container {
            &.menu-direction-top-left,
            &.menu-direction-bottom-left {
                right: 1.5rem;
            }

            &.menu-direction-top-right,
            &.menu-direction-bottom-right {
                left: 1.5rem;
            }

            &.menu-direction-top-left,
            &.menu-direction-top-right {
                bottom: 90%;
            }

            &.menu-direction-bottom-left,
            &.menu-direction-bottom-right {
                top: 90%;
            }
        }
    }
}