@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.context-menu-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .context-menu {
        position: fixed;
        border-radius: var(--border-radius);
        background-color: var(--modal-background-color);
        box-shadow: 0 1.35rem 2.7rem @color-background-dark5-40,
                    0 1.1rem 0.85rem @color-background-dark5-20;
    }
}